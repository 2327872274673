import { format } from 'date-fns'
import { pythonApi } from '../../../app/services/api/api'
import { downloadFile } from '../../../utils/helpers'
import { DeviceResult } from '../../deviceConfiguration/api/types'
import { prepareFilters } from './helpers'
import { ApiResponse, AlarmTypesResponse, Alarm, AlarmQueryParams } from './types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAlarms: builder.query<ApiResponse, any>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        if (filters.active_tab !== 2) {
          return { url: `/alarms-trigger/?${searchParams}`, method: 'GET' }
        }
        return { url: `/alarms/?${searchParams}`, method: 'GET' }
      },
    }),
    getAllAlarmTypes: builder.query<AlarmTypesResponse, void>({
      query: () => {
        return { url: `/alarms-type/`, method: 'GET' }
      },
    }),
    resolveAlarm: builder.mutation<
      { message: string; is_resolved: boolean },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/alarms-trigger/${id}/resolve/`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    alarmSeen: builder.mutation({
      query: () => ({
        url: `/alarms-trigger/seen/`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {}, 
      }),
    }),
    commentAlarm: builder.mutation<any, { text: string; alarm_trigger: number }>({
      query: (body) => ({
        url: `/alarm-comments/`,
        method: 'POST',
        body: body,
      }),
    }),
    acknowledgeAlarm: builder.mutation<
      { message: string; is_acknowledged: boolean },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/alarms-trigger/${id}/acknowledge/`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    // getAllPhysicalDevices: builder.query<PhysDevicesResponse, any>({
    getAllPhysicalDevices: builder.query<DeviceResult[], any>({
      query: (props) => {
        const queryParams = new URLSearchParams()
        if (props.location) {
          queryParams.set('location', props.location)
        }
        if (props.group) {
          queryParams.set('group', props.group)
        }
        if (props.measurement) {
          queryParams.set('measurement', props.measurement)
        }
        return {
          url: `/physical-devices/?${queryParams.toString()}&limit=999`,
          method: 'GET',
        }
      },
    }),
    getAlarmById: builder.query<Alarm, { id: number }>({
      query: ({ id }) => ({
        url: `/alarms/${id}`,
        method: 'GET',
      }),
    }),
    createNewAlarm: builder.mutation<Alarm, AlarmQueryParams>({
      query: (body) => ({
        url: `/alarms/`,
        method: 'POST',
        body: body,
      }),
    }),
    editAlarm: builder.mutation<Alarm, AlarmQueryParams & { id: number }>({
      query: (body) => ({
        url: `/alarms/${body.id}/`,
        method: 'PATCH',
        body: body,
      }),
    }),
    deleteAlarm: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/alarms/${id}/`,
        method: 'DELETE',
      }),
    }),
    patchAlarmById: builder.mutation<Alarm, { id: number; body: Partial<Alarm> }>({
      query: ({ id, body }) => ({
        url: `/alarms/${id}/`,
        method: 'PATCH',
        body,
      }),
    }),
    exportCSV: builder.mutation<void, { filters: any }>({
      query: ({ filters }) => {
        const searchParams = prepareFilters(filters)
        return {
          url: `/alarms/export_alarms/?${searchParams}`,
          method: 'POST',
          responseHandler: async (response) => {
            if (response.ok) {
              const href = window.URL.createObjectURL(await response.blob())
              const filename = `Alarms_${format(new Date(), 'yyyy-MM-dd')}.csv`
              downloadFile(href, filename)
            }
            // Return null or an empty object to ensure the state remains serializable
            return null
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAlarmsQuery,
  useCreateNewAlarmMutation,
  useGetAllAlarmTypesQuery,
  useGetAllPhysicalDevicesQuery,
  useGetAlarmByIdQuery,
  usePatchAlarmByIdMutation,
  useResolveAlarmMutation,
  useCommentAlarmMutation,
  useAcknowledgeAlarmMutation,
  useExportCSVMutation,
  useDeleteAlarmMutation,
  useAlarmSeenMutation,
  useEditAlarmMutation,
} = extendedApi
