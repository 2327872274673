import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
  Flex,
  Tooltip,
} from '@chakra-ui/react'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryActionCreatorResult,
  QueryDefinition,
} from '@reduxjs/toolkit/query'
import React from 'react'
import { OutlinedDeleteIcon } from '../../../assets/icons/OutlinedDeleteIcon'
import { useDeleteAlarmMutation } from '../api/alarmsApi'
import { ApiResponse } from '../api/types'
import { toast } from 'react-toastify'

type Props = {
  alarmId: number
  refetchAllAlarms: () => QueryActionCreatorResult<
    QueryDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      'List' | 'ReportData',
      ApiResponse,
      'pythonApi'
    >
  >
}
export const DeleteAlarmModal = ({ alarmId, refetchAllAlarms }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [trigger] = useDeleteAlarmMutation()
  const onConfirm = async () => {
    const toastId = toast.info('Deleting alarm...', {
      style: { padding: '10px 20px' },
      autoClose: false,
      isLoading: true,
      progress: undefined,
    })
    try {
      await trigger({ id: alarmId })
      toast.update(toastId, {
        render: `Successfully deleted`,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      })
    } catch (_error) {
      toast.update(toastId, {
        render: `Failed to delete alarm!`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      })
    }
    refetchAllAlarms()
    onClose()
  }
  return (
    <Flex
      color="icon.strong"
      bg="#F7F9FD"
      rounded="lg"
      align="center"
      justifyContent="space-around"
      boxSize="32px"
      _hover={{ bg: '#E8EEFD' }}
      onClick={(e) => {
        onOpen()
        e.stopPropagation()
      }}
    >
      <Tooltip
        hasArrow
        rounded="lg"
        label={<Text pl="10px" p="6px">Delete</Text>}
        placement="top"
        bg="heading.dark"
      >
        <OutlinedDeleteIcon />
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete alarm</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>Are you sure you want to delete this alarm? </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onConfirm} colorScheme="blue" mr={3}>
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
