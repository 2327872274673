import React, { useCallback, useMemo } from 'react'
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Collapse,
} from '@chakra-ui/react'
import { CloseIcon } from '../../../assets/icons/Close'
import SingleSelect from '../../reports/components/SingleSelect'
import { useForm } from 'react-hook-form'
import Severity from './Severity'
import { useGetAllAlarmTypesQuery, useGetAllPhysicalDevicesQuery } from '../api/alarmsApi'
import { useGetDeviceGroupListQuery } from '../../deviceConfiguration/api/deviceGroupConfigurationApi'

interface Filters {
  severity?: string[]
  location?: number
  device?: number
  group?: number
  alarmType?: number
}

const FilterModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  locations: any
  setFilters: (
    value: React.SetStateAction<{
      severity?: string[]
      location?: number
      device?: number
      group?: number
      alarmType?: number
    }>,
  ) => void
  filters: Filters
}> = ({ isOpen, onClose, locations, setFilters, filters }) => {
  const {
    watch,
    handleSubmit,
    setValue,
    formState,
    reset,
  } = useForm<{
    severity?: string[]
    location: number
    device?: number
    group?: number
    alarmType?: number
  }>()

  const severity = watch('severity') || filters.severity || []
  const location = watch('location') || filters.location
  const device = watch('device') || filters.device
  const group = watch('group') || filters.group
  const alarmType = watch('alarmType') || filters.alarmType

  const { data: alarmTypes } = useGetAllAlarmTypesQuery()
  const { data: devicesGroups } = useGetDeviceGroupListQuery({})
  const { data: userPhysDevices } = useGetAllPhysicalDevicesQuery(
    {
      location: locations.map((x) => x.id.toString()),
    },
    { skip: locations.length === 0 }
  )

  const isFilterSelected = useMemo(
    () =>
      severity?.length > 0 ||
      location ||
      device ||
      group ||
      alarmType,
    [severity, location, device, group, alarmType],
  )

  const setSelectedSeverity = useCallback(
    (severity: string[]) => {
      setValue('severity', severity)
    },
    [setValue],
  )

  const clearFields = useCallback(() => {
    onClose()
    reset()
    setFilters({
      severity: [],
      location: undefined,
      device: undefined,
      group: undefined,
      alarmType: undefined,
    })
  }, [onClose, reset, setFilters])

  const handleOnClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const handleFormSubmit = useCallback(
    (data: Filters) => {
      setFilters(data)
      onClose()
    },
    [setFilters, onClose],
  )

  const locationSelect = useMemo(
    () =>
      locations.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [locations],
  )

  const userDevicesSelect = useMemo(
    () =>
      userPhysDevices?.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [userPhysDevices],
  )

  const devicesGroupsSelect = useMemo(
    () =>
      devicesGroups?.map(({ id, name }) => ({
        id,
        name,
      })),
    [devicesGroups],
  )

  const alarmTypesSelect = useMemo(
    () =>
      alarmTypes?.results.map(({ id, name }) => ({
        id,
        name,
      })),
    [alarmTypes],
  )

  return (
    <Modal onClose={handleOnClose} isOpen={isOpen} scrollBehavior="inside" size="xl">
      <ModalOverlay />
      <ModalContent>
        <Flex justifyContent="space-between" p="24px">
          <Text fontSize="24px" lineHeight="30px" fontWeight="700" color="text.dark">
            Filter Alarms
          </Text>
          <Button variant="ghost" onClick={handleOnClose}>
            <CloseIcon color="icon.primary" />
          </Button>
        </Flex>
        <ModalBody px="24px" pt="0" pb="24px">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Flex direction="column" gap="5">
              <Severity severity={severity} setSelectedSeverity={setSelectedSeverity} />
              <SingleSelect
                header="Location"
                items={locationSelect}
                setSelectedOption={(selectedOptionId: number) =>
                  setValue('location', selectedOptionId)
                }
                selectedOption={location}
              />
              <SingleSelect
                header="Device"
                items={userDevicesSelect}
                setSelectedOption={(selectedOptionId: number) =>
                  setValue('device', selectedOptionId)
                }
                selectedOption={device}
              />
              <SingleSelect
                header="Group"
                items={devicesGroupsSelect}
                setSelectedOption={(selectedOptionId: number) =>
                  setValue('group', selectedOptionId)
                }
                selectedOption={group}
              />
              <SingleSelect
                header="Type"
                items={alarmTypesSelect}
                setSelectedOption={(selectedOptionId: number) =>
                  setValue('alarmType', selectedOptionId)
                }
                selectedOption={alarmType}
              />
            </Flex>
            <Flex direction="column" mt="24px">
              <Button
                type="submit"
                colorScheme="button.primary"
                disabled={!isFilterSelected}
                mb="2"
              >
                Filter
              </Button>
              <Collapse in={!!isFilterSelected}>
                <Button onClick={clearFields} variant="ghost" w="full">
                  Reset filters
                </Button>
              </Collapse>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FilterModal
