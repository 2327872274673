import React, { useMemo } from 'react'
import CustomModal from '../../reports/pages/components/CustomModal'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import { formatAlarmDate, getDevices, getFilteredFields } from '../../../utils/helpers';
import { STATES } from '../../../utils/consts';
import { useGetAllAlarmsQuery } from '../api/alarmsApi';
import { Alarm } from '../api/types';

const headersMapper = {
    "Active/Past": [
        "Name",
        "Type",
        "Severity",
        "Location",
        "Group",
        "Device",
        "Measurement Type",
        "Parameter",
        "Description",
        "How to resolve",
        "Triggered value",
        "Triggered severity",
        "Triggered description",
        "Triggered at",
        "Acnowledged at",
        "Acknowledged by",
        "Resolved at",
        "Resolved by",
    ],
    "Registered": [
        "Name",
        "Type",
        "Severity",
        "Location",
        "Group",
        "Device",
        "Measurement Type",
        "Parameter",
        "Description",
        "How to resolve",
        "Created at",
        "Comparison Operator",
        "Comparison Value",
        "Hysteresis Upper",
        "Hysteresis Lower",
    ],
}

const getValueMapper = (alarm: Alarm, extraProps?: { devices?: any[]; locations?: any[]; groups?: any[], activeTab: any }) => {
    const multistateFields = Array.isArray(alarm?.multistate_alarm?.multistate_fields)
        ? alarm.multistate_alarm.multistate_fields
        : []

    const filteredFields = getFilteredFields(extraProps.activeTab, alarm?.value, multistateFields)

    const multistateType = alarm?.multistate_alarm?.state
    const state = alarm?.multistate_alarm ? STATES?.find(x => x.id === Number(multistateType)) : null

    const isLevelSeverity = alarm?.type?.name === "Level"

    return {
        "Name": () => alarm.name,
        "Type": () => alarm.type?.name,
        "Severity": () => alarm.severity,
        "Location": () => extraProps?.locations,
        "Group": () => extraProps?.groups,
        "Device": () => extraProps?.devices?.map(dev => dev.title),
        "Measurement Type": () => alarm.devices?.[0]?.measurement_types?.[0]?.name,
        "Parameter": () => alarm.parameter,
        "Description": () => alarm.description,
        "How to resolve": () => alarm.instructions,
        "Triggered value": () =>
            alarm?.multistate_alarm
                ? filteredFields?.map((record) => `${state?.name || "Unknown"} ${record.value}`).join(", ")
                : alarm?.value,
        "Triggered severity": () => filteredFields?.map((record) => record.severity).join(", "),
        "Triggered description": () => filteredFields?.map((record) => record.description).join(", "),
        "Triggered at": () => formatAlarmDate(alarm.triggered_at, ""),
        "Acnowledged at": () => formatAlarmDate(alarm.acknowledged_at, ""),
        "Acknowledged by": () => alarm.acknowledged_by,
        "Resolved at": () => formatAlarmDate(alarm.resolved_at, ""),
        "Resolved by": () => alarm.resolved_by,
        "Created at": () => formatAlarmDate(alarm.created_at, ""),
        "Comparison Operator": () => isLevelSeverity ? alarm?.level_alarm?.comparison_operator : '',
        "Comparison Value": () => isLevelSeverity ? `${alarm?.level_alarm?.comparison_value?.toFixed(2)} ${alarm?.measurement_types?.name}` : '',
        "Hysteresis Upper": () => isLevelSeverity ? `${alarm?.level_alarm?.upper_border_percent}%` : '',
        "Hysteresis Lower": () => isLevelSeverity ? `${alarm?.level_alarm?.bottom_border_percent}%` : '',
    }
}

const formatCSVValue = (value: any) => {
    if (!value || value.length === 0) return '""'
    if (Array.isArray(value)) value = value.join(", ")
    const stringValue = String(value).replace(/"/g, '""')
    return `"${stringValue}"`
}

const getTabName = (activeTab: number) => {
    switch (activeTab) {
        case 0:
            return "Active"
        case 1:
            return "Past"
        case 2:
            return "Registered"
        default:
            return ""
    }
}

const exportAlarmsToCSV = (alarms: any[], activeTab: any) => {
    const toastId = toast.info("Exporting...", {
        style: { padding: "10px 20px" },
        autoClose: false,
        isLoading: true,
    })

    if (!alarms || alarms.length === 0) {
        toast.update(toastId, {
            render: "No alarms to export",
            type: "error",
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    const tab = activeTab === 2 ? "Registered" : "Active/Past"
    const headers = headersMapper[tab]

    const csvRows = alarms.map(alarm => {
        const devices = getDevices(alarm)
        const uniqueLocations = Array.from(new Set(devices.map((device) => device?.location.title)))
        const uniqueGroups = Array.from(new Set(devices.map((device) => device?.group?.name))).filter(Boolean)

        const valueMapper = getValueMapper(alarm, {
            devices: devices,
            locations: uniqueLocations,
            groups: uniqueGroups,
            activeTab: activeTab,
        })

        return headers.map(header => formatCSVValue(valueMapper[header]?.())).join(",")
    })

    const csvContent = [headers.join(","), ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=UTF-8' })
    const href = URL.createObjectURL(blob)
    const tabName = getTabName(activeTab)
    const filename = `${tabName} alarms ${new Date().toISOString().split("T")[0]}.csv`

    const link = document.createElement("a")
    link.href = href
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    toast.update(toastId, {
        render: `Successfully export ${alarms?.length} alarms`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
    })
}

export const ExportAlarmsModal = ({ isOpen, handleClose, activeTab, filters, searchInput }) => {
    const {
        handleSubmit,
        watch,
        setValue,
        register,
        reset,
        formState: { errors },
    } = useForm()

    const { data } = useGetAllAlarmsQuery({
        filters: {
            ...filters,
            is_resolved: activeTab === 1,
            active_tab: activeTab,
            search: searchInput,
            page_size: 9999,
        },
    }, { refetchOnMountOrArgChange: true })

    const alarms = useMemo(() => data?.results, [data])

    const startDate = watch('startDate') || null
    const endDate = watch('endDate') || null

    const filterAlarmsByDate = (alarms: any[], activeTab: number) => {
        const getDateField = (alarm: any) => {
            switch (activeTab) {
                case 0:
                    return alarm?.triggered_at
                case 1:
                    return alarm?.resolved_at
                case 2:
                    return alarm?.created_at
                default:
                    return null
            }
        }

        const normalizeDate = (date: Date | null) => date ? new Date(date.setHours(0, 0, 0, 0)) : null

        const normalizedStartDate = normalizeDate(startDate)
        const normalizedEndDate = normalizeDate(endDate)

        if (normalizedEndDate) normalizedEndDate.setHours(23, 59, 59, 999)

        return alarms.filter(alarm => {
            const alarmDate = getDateField(alarm)
            if (!alarmDate) return false

            const alarmTime = new Date(alarmDate).getTime()

            const isAfterStart = normalizedStartDate ? alarmTime >= normalizedStartDate.getTime() : true
            const isBeforeEnd = normalizedEndDate ? alarmTime <= normalizedEndDate.getTime() : true

            return isAfterStart && isBeforeEnd
        })
    }


    const handleExport = async () => {
        const filteredAlarms = filterAlarmsByDate(alarms, activeTab)
        exportAlarmsToCSV(filteredAlarms, activeTab)
        handleClose()
        reset()
    }

    return (
        <CustomModal
            title="Export alarms"
            tooltipLabel="Please provide a date range"
            handleSend={handleSubmit(() => handleExport())}
            isDisabled={!startDate || !endDate}
            isLoading={false}
            isOpen={isOpen}
            onClose={() => {
                handleClose()
                reset()
            }}
            buttonName="Export"
        >
            {/* @ts-ignore */}
            <DateRangeInputs
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
                selectedStartName="startDate"
                selectedEndName="endDate"
                startDate={startDate}
                endDate={endDate}
            />
        </CustomModal>
    )
}
