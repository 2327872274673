import { pythonApi } from '../../../app/services/api/api'

import { DeviceGroupListType } from './types'

interface GroupQueryParams {
    measurementTypeId?: string
}

const extendedApi = pythonApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceGroupList: builder.query<DeviceGroupListType[], GroupQueryParams>({
            query: (props: GroupQueryParams) => {
                const queryParams = new URLSearchParams()
                if (props.measurementTypeId) {
                  queryParams.set('measurement_type', props.measurementTypeId)
                }
                return {
                  url: `/devices-groups/?${queryParams.toString()}`,
                  method: 'GET',
                }
              },
              providesTags: ['List']
        }),
        createDeviceGroup: builder.mutation<any, { name: string, devices: number[] }>({
            query: (body) => {
                return {
                    url: `/devices-groups/`,
                    method: 'POST',
                    body: body,
                }
            },
            invalidatesTags: ['List']
        }),

        deleteDeviceGroup: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/devices-groups/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['List']
        }),

        updateDeviceGroup: builder.mutation<any, { id: number, name: string, devices: number[] }>({
            query: (body) => {
                return {
                    url: `/devices-groups/${body.id}/`,
                    method: 'PATCH',
                    body: body,
                }
            },
            invalidatesTags: ['List']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetDeviceGroupListQuery, useCreateDeviceGroupMutation, useDeleteDeviceGroupMutation, useUpdateDeviceGroupMutation
} = extendedApi
