import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Flex,
  Grid,
  Text,
  Tooltip,
  Tag,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Label } from '../../../app/components/Label/Label'
import { CheckCircleIcon } from '../../../assets/icons/CheckCircle'
import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { AccordionPanelAlarms } from './AccordionPanelAlarms'
import EditAlarmModal from './EditAlarmModal'
import { Alarm, ApiResponse } from '../api/types'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryActionCreatorResult,
  QueryDefinition,
} from '@reduxjs/toolkit/query'
import { DeleteAlarmModal } from './DeleteAlarmModal'
import {
  useAcknowledgeAlarmMutation,
  useEditAlarmMutation,
  useCommentAlarmMutation,
  useResolveAlarmMutation,
} from '../api/alarmsApi'
import { toast } from 'react-toastify'
import AlarmCommentModal from './AlarmCommentModal'
import { CommentIcon } from '../../../assets/icons/CommentIcon'
import { useAlarmsCountContext } from '../../../context/AlarmsCountContext'
import { getAlarmSeverity, formatAlarmDate, getDevices } from '../../../utils/helpers'

type Props = {
  index: number
  activeIndex: number
  alarm: Alarm
  refetchAllAlarms: () => QueryActionCreatorResult<
    QueryDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      'List' | 'ReportData',
      ApiResponse,
      'pythonApi'
    >
  >
}
export const CustomAccordionItem = ({
  index,
  activeIndex,
  alarm,
  refetchAllAlarms,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [editAlarm] = useEditAlarmMutation()
  const [resolveAlarm] = useResolveAlarmMutation()
  const [commentAlarm] = useCommentAlarmMutation()
  const [acknowledgeAlarm] = useAcknowledgeAlarmMutation()
  const alarmCountContext = useAlarmsCountContext()

  const dateMapping = {
    0: alarm?.triggered_at,
    1: alarm?.resolved_at,
    2: alarm?.created_at,
  }

  const devices = getDevices(alarm)
  const severity = getAlarmSeverity(alarm, activeIndex)
  const uniqueLocations = Array.from(
    new Set(devices.map((device) => device?.location.title)),
  )
  const uniqueGroups = Array.from(
    new Set(devices.map((device) => device?.group?.name)),
  ).filter(Boolean)

  const handleEdit = async (e) => {
    e.stopPropagation()
    const enableDisable = !alarm.is_enabled ? 'enabled' : 'disabled'

    try {
      await editAlarm({ id: alarm.id, is_enabled: !alarm.is_enabled }).unwrap()
      toast.success(`Alarm ${enableDisable}`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
      refetchAllAlarms()
    } catch (error) {
      toast.error(`Failed to ${enableDisable} alarm`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }
  }
  const getBorderLeftColor = () => {
    if (activeIndex === 0) {
      return alarm.is_acknowledged || alarm.is_resolved ? '#FFA07A' : '#157FEE'
    } 
    return 'none'
  }
  const handleResolve = async () => {
    try {
      await resolveAlarm({ id: alarm?.id }).unwrap()
      alarmCountContext.setAlarmsCount((prevCount: number) => Math.max(prevCount - 1, 0))
      toast.success('Alarm resolved successfully', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
      refetchAllAlarms()
    } catch (error) {
      toast.error('Error while resolving alarm', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }
  }

  const handleComment = async (comment: string) => {
    try {
      await commentAlarm({ text: comment, alarm_trigger: alarm?.id }).unwrap()
      toast.success('Comment added successfully', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
      refetchAllAlarms()
    } catch (error) {
      toast.error('Error while adding comment', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }
  }

  const handleAcknowledge = async (e) => {
    e.stopPropagation()
    try {
      await acknowledgeAlarm({ id: alarm?.id }).unwrap()
      alarmCountContext.setAlarmsCount((prevCount: number) => Math.max(prevCount - 1, 0))
      toast.success('Alarm acknowledged successfully', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
      refetchAllAlarms()
    } catch (error) {
      toast.error('Error acknowledge alarm', {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }
  }

  return (
    <AccordionItem
      borderLeft={`2px solid ${getBorderLeftColor()}`}
      outline="none"
      boxShadow="none"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            outline="none"
            boxShadow="none"
            px="0"
            sx={{
              outline: 'none',
              _hover: { bg: isExpanded ? 'none' : 'gray.100' },
              _focus: { boxShadow: 'none' },
              transitionDuration: '0.3s',
            }}
          >
            <Grid
              templateColumns="150px 0.7fr 0.7fr 1fr 1fr 1fr 1fr 1fr"
              gap={6}
              p={4}
              key={index}
              w="full"
            >
              <Flex align="center">
                <AccordionIcon mr="4" />
                <Flex direction="column" textAlign="left">
                  <Tooltip label={alarm?.name} hasArrow placement="top">
                    <Text
                      fontSize="14px"
                      fontWeight="700"
                      color="text.dark"
                      textAlign="left"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="150px"
                    >
                      {alarm?.name?.length > 14 ? `${alarm.name.slice(0, 14)}...` : alarm?.name}
                    </Text>
                  </Tooltip>
                </Flex>
              </Flex>
              <Flex align="center">
                <Tag variant="subtle" colorScheme="cyan">
                  {alarm?.type.name}
                </Tag>
              </Flex>
              <Flex align="center">
                <Label type={severity} />
              </Flex>
              <Flex align="center">
                <Tooltip label={uniqueLocations.join(', ')} hasArrow placement="top">
                  <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="text.dark"
                    textAlign="left"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="150px"
                  >
                    {uniqueLocations.length > 0
                      ? `${uniqueLocations[0]}${uniqueLocations.length > 1
                        ? ', ' + uniqueLocations[1] + '...'
                        : ''
                      }`
                      : '-'}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex align="center">
                <Tooltip label={uniqueGroups.join(', ')} hasArrow placement="top">
                  <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="text.dark"
                    textAlign="left"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="150px"
                  >
                    {uniqueGroups.length > 0
                      ? `${uniqueGroups[0]}${uniqueGroups.length > 1 ? ', ' + uniqueGroups[1] + '...' : ''
                      }`
                      : '-'}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex align="center">
                <Tooltip
                  label={devices?.map((device) => device.title).join(', ')}
                  hasArrow
                  placement="top"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="text.dark"
                    textAlign="left"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="150px"
                  >
                    {devices?.length > 0
                      ? `${devices[0].title}${devices.length > 1 ? ', ' + devices[1].title + '...' : ''
                      }`
                      : '-'}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex align="center">
                <Text
                  fontSize="12px"
                  fontWeight="400"
                  color="text.light"
                  textAlign="left"
                >
                  {formatAlarmDate(dateMapping[activeIndex])}
                </Text>
              </Flex>
              <Flex gap="2">
                {activeIndex === 0 && (
                  <>
                    <Tooltip
                      hasArrow
                      rounded="md"
                      placement="top"
                      bg="heading.dark"
                      label={
                        <Text pl="10px" p="6px">
                          {alarm.is_acknowledged
                            ? 'Alarm already acknowledged'
                            : 'Acknowledge'}
                        </Text>
                      }
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: alarm.is_acknowledged ? '#F7F9FD' : '#E8EEFD' }}
                        opacity={alarm.is_acknowledged ? 0.5 : 1}
                      >
                        <ViewIcon
                          onClick={alarm.is_acknowledged ? undefined : handleAcknowledge}
                          pointerEvents={alarm.is_acknowledged ? 'none' : 'auto'}
                        />
                      </Flex>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      rounded="md"
                      placement="top"
                      bg="heading.dark"
                      label={
                        <Text pl="10px" p="6px">
                          Mark as resolved
                        </Text>
                      }
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: '#E8EEFD' }}
                      >
                        <CheckCircleIcon
                          as="button"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleResolve()
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  </>
                )}
                {activeIndex === 2 && (
                  <Flex gap="2" alignItems="center">
                    <Tooltip
                      hasArrow
                      rounded="lg"
                      label={
                        <Text pl="10px" p="6px">
                          {alarm?.is_enabled ? 'Disable' : 'Enable'}
                        </Text>
                      }
                      placement="top"
                      bg="heading.dark"
                    >
                      <Flex
                        as="button"
                        onClick={handleEdit}
                        w="50px"
                        h="28px"
                        bg={alarm?.is_enabled ? 'green.400' : 'gray.600'}
                        rounded="full"
                        p="4px"
                        align="center"
                        transition="background 0.3s ease"
                        position="relative"
                      >
                        <Box
                          w="20px"
                          h="20px"
                          bg="white"
                          rounded="full"
                          transition="transform 0.3s ease"
                          transform={
                            alarm?.is_enabled ? 'translateX(22px)' : 'translateX(0)'
                          }
                          shadow="md"
                        />
                      </Flex>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      rounded="lg"
                      label={
                        <Text pl="10px" p="6px">
                          Edit
                        </Text>
                      }
                      placement="top"
                      bg="heading.dark"
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: '#E8EEFD' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsEditOpen(true)
                        }}
                      >
                        <EditIcon />
                      </Flex>
                    </Tooltip>
                    <DeleteAlarmModal
                      alarmId={alarm.id}
                      refetchAllAlarms={refetchAllAlarms}
                    />
                  </Flex>
                )}
                {(activeIndex === 0 || activeIndex === 1) && (
                  <Flex gap="2" alignItems="center">
                    <Tooltip
                      hasArrow
                      rounded="lg"
                      label={
                        <Text pl="10px" p="6px">
                          Comment
                        </Text>
                      }
                      placement="top"
                      bg="heading.dark"
                    >
                      <Flex
                        color="icon.strong"
                        bg="#F7F9FD"
                        rounded="lg"
                        align="center"
                        justifyContent="space-around"
                        boxSize="32px"
                        _hover={{ bg: '#E8EEFD' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onOpen()
                        }}
                      >
                        <CommentIcon count={alarm.comments.length} />
                      </Flex>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            </Grid>
          </AccordionButton>
          <EditAlarmModal
            isOpen={isEditOpen}
            handleClose={() => setIsEditOpen(false)}
            refetchAllAlarms={refetchAllAlarms}
            alarm={alarm}
          />
          <AlarmCommentModal
            handleAction={handleComment}
            isOpen={isOpen}
            handleClose={onClose}
          />
          <AccordionPanelAlarms alarm={alarm} activeTab={activeIndex} />
        </>
      )}
    </AccordionItem>
  )
}
